import request from '@utils/fetch'

//新增用户
export function insertMfUser(data) {
  return request({
    url: '/mf/insertMfUser',
    method: 'post',
    data
  })
}

//修改用户信息
export function updateMfUser(data) {
  return request({
    url: '/mf/updateMfUser',
    method: 'post',
    data
  })
}

//删除用户信息
export function deleteMfUser(data) {
  return request({
    url: '/mf/deleteMfUser',
    method: 'post',
    data
  })
}

//查询所有商户
export function findMfAgent(data) {
  return request({
    url: '/mf/findMfAgent',
    method: 'post',
    data
  })
}

//查询代理商
export function findMfMch(data) {
  return request({
    url: '/mf/findMfMch',
    method: 'post',
    data
  })
}

//查询老商城用户
export function findDockorOldUser(data) {
  return request({
    url: '/mf/findDockorOldUser',
    method: 'post',
    data
  })
}